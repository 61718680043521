<script>
    import {onMount} from 'svelte';

    export let zip;
    export let image;

    let input;

    const getZip = place => place.address_components.find(item => item.types.includes('postal_code'))?.long_name;

    const fillInputWithResult = (input, result) => {
        const locationName = [
            `${result.address_components.filter(i => i.types.includes('locality'))[0].long_name} ${getZip(result)}`,
            result.address_components.filter(i => i.types.includes('country'))[0].long_name,
        ];

        input.value = locationName.join(', ');
    }

    const setZipFromLatLng = ({lat, lng}, callback) => {
        const geocoder = new google.maps.Geocoder();

        geocoder.geocode({
            location: {lat, lng,}
        }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    callback(results[0]);
                }
            }
        });
    };

    const callWithZip = (form, callback) => {
        const place = form.getPlace();
        const detectedZip = getZip(place);

        if (typeof detectedZip === 'undefined') {
            setZipFromLatLng({
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
            }, callback);
        } else {
            callback(place);
        }
    }

    const getLocation = () => {
        navigator.geolocation.getCurrentPosition(position => {
            setZipFromLatLng({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            }, result => {
                zip = getZip(result);
                fillInputWithResult(input, result);
            });
        });
    };

    onMount(() => {
        const autocompleteFrom = new google.maps.places.Autocomplete(input, {
            types: ['(regions)'],
            componentRestrictions: {
                country: 'de',
            },
        });

        autocompleteFrom.addListener('place_changed', () => {
            callWithZip(autocompleteFrom, result => {
                zip = getZip(result);
                fillInputWithResult(input, result);
            });
        });
    });
</script>

<header>
    <img class="map"
         width="320"
         height="429"
         loading="lazy"
         src="https://pollen-forecast.ratgeber-allergie.com/assets/{image}"
         alt="Pollenflug in Deutschland">
    <label for="plz">
        PLZ, Ort...
        <input id="plz" type="text" bind:this={input} placeholder="PLZ, Ort...">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
             stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
             class="feather feather-search">
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
        </svg>
    </label>
    <button on:click={getLocation}>
        Meinen Standort verwenden
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
             stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
             class="feather feather-map-pin">
            <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
            <circle cx="12" cy="10" r="3"></circle>
        </svg>
    </button>
</header>

<style>
    header {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        position: relative;
        background-color: var(--color-gray);
        padding: 1rem 1rem 1rem calc(160px + 5rem);
        min-height: 8rem;
        align-items: center;
        margin-top: 2.5rem;
        margin-bottom: 3rem;
    }

    .map {
        position: absolute;
        top: 50%;
        left: 1rem;
        transform: translateY(-50%);
        width: 160px;
        height: auto;
    }

    button,
    input {
        font-size: .9375rem;
        margin-bottom: 0;
        padding: .875rem 2.5rem .875rem 1rem;
        border: 1px solid var(--color-gray-dark);
        border-radius: 0;
        background-color: white;
    }

    label {
        font-size: 0;
        display: inline-flex;
        flex-direction: row;
        position: relative;
    }

    input {
        width: 100%;
    }

    label,
    button {
        width: 100%;
        max-width: calc(50% - .5rem);
    }

    button {
        appearance: none;
        cursor: pointer;
        text-align: left;
        color: var(--color-gray-dark);
        display: inline-flex;
        flex-direction: row;
        margin-left: 1rem;
        position: relative;
    }

    svg {
        width: 1em;
        height: 1em;
        margin-left: auto;
        font-size: 1.5rem;
        position: absolute;
        top: 50%;
        right: .75rem;
        transform: translateY(-50%);
        z-index: 1;
    }

    @media (max-width: 992px) {
        header {
            margin-top: 5rem;
            margin-bottom: .5rem;
            padding-left: 1rem;
            padding-top: 5.5rem;
            justify-content: center;
        }

        .map {
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 120px;
        }

        input {
            min-width: 0;
            width: 100%;
            max-width: 100%;
        }

        label {
            width: calc(100% - calc(4rem + 2px));
            max-width: 18.75rem;
        }

        button {
            font-size: 0;
            line-height: 1.1rem;
            padding-right: 2rem;
            min-width: 0;
            width: auto;
        }
    }
</style>
