<script>
    export let loaded = false;

    const onLoad = () => {
        loaded = true;
    };
</script>

<svelte:head>
    <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCQNR21HzQTU0SG73ErOdFmpvoIVW9vFMg&libraries=places" on:load={onLoad}></script>
</svelte:head>

<style>
</style>
