<script>
    export let zip;

    $:data = (async () => {
        const response = await fetch(`https://www.ratgeber-allergie.com/__data/pollen?plz=${zip}`)
        return await response.json()
    })()
</script>

{#await data}
    <p>...lade Daten</p>
{:then data}
    {#if data?.pollenData.length}
        <main>
            <dl>
                <dt class="intensity--0">0</dt>
                <dd>Keine Belastung</dd>
                <dt class="intensity--1">1</dt>
                <dd>Schwache Belastung</dd>
                <dt class="intensity--2">2</dt>
                <dd>Mittlere Belastung</dd>
                <dt class="intensity--3">3</dt>
                <dd>Starke Belastung</dd>
            </dl>
            <ol class="day-list">
                <li class="pollen-list pollen-names">
                    <p class="text--primary pl-0">
                        Pollen&shy;arten
                    </p>
                    <ul>
                        {#each data.pollenData[0] as pollen, i}
                            <li class="pl-0">
                                {pollen.name}
                            </li>
                        {/each}
                    </ul>
                </li>
                {#each data.pollenData as day, i}
                    <li class="pollen-list day--{i}">
                        <p class="text--center text--primary">
                            {new Date(Date.now() + 24 * 3600 * 1000 * i).toLocaleString('de-DE', {
                                weekday: 'short',
                                month: '2-digit',
                                day: '2-digit',
                            })}
                        </p>
                        <ul>
                            {#each day as pollen, i}
                                <li class="pollen-list__item intensity--{pollen.intensity}">
                                    {pollen.intensity}
                                </li>
                            {/each}
                        </ul>
                    </li>
                {/each}
            </ol>
        </main>
        <footer>
            <p class="small">
                Quelle: www.wetter.com
            </p>
        </footer>
    {:else}
        <p>Es ist ein Fehler aufgetreten</p>
    {/if}
{:catch error}
    <p>Es ist ein Fehler aufgetreten</p>
{/await}

<style>
    ol,
    ul {
        list-style: none;
        padding-left: 0;
        margin: 0;
    }

    p {
        padding: .5rem 0;
        font-weight: 500;
    }

    .text--center {
        text-align: center;
    }

    .text--primary {
        color: var(--color-primary);
    }

    .pl-0 {
        padding-left: 0 !important;
    }

    .day-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-gap: .75rem .5rem;
        padding: 0 1.5rem;
    }

    .day-list > li,
    .pollen-list > ul {
        display: contents;
    }

    .pollen-list > p {
        grid-row-start: 1;
        margin-bottom: 0;
    }

    .pollen-list > p,
    .pollen-list__item {
        justify-self: center;
        width: 100%;
    }

    .pollen-list > ul > li:nth-child(1) {
        grid-row-start: 2;
    }

    .pollen-list > ul > li:nth-child(2) {
        grid-row-start: 3;
    }

    .pollen-list > ul > li:nth-child(3) {
        grid-row-start: 4;
    }

    .pollen-list > ul > li:nth-child(4) {
        grid-row-start: 5;
    }

    .pollen-list > ul > li:nth-child(5) {
        grid-row-start: 6;
    }

    .pollen-list > ul > li:nth-child(6) {
        grid-row-start: 7;
    }

    .pollen-list > ul > li:nth-child(7) {
        grid-row-start: 8;
    }

    .pollen-list > ul > li:nth-child(8) {
        grid-row-start: 9;
    }

    .pollen-list > ul > li:nth-child(9) {
        grid-row-start: 10;
    }

    .pollen-list > ul > li:nth-child(10) {
        grid-row-start: 11;
    }

    .pollen-list > ul > li:nth-child(11) {
        grid-row-start: 12;
    }

    .pollen-list > ul > li:nth-child(12) {
        grid-row-start: 13;
    }

    .pollen-list > ul > li:nth-child(13) {
        grid-row-start: 14;
    }

    .pollen-list > ul > li:nth-child(14) {
        grid-row-start: 15;
    }

    .pollen-list > ul > li:nth-child(15) {
        grid-row-start: 16;
    }

    .pollen-list > ul > li:nth-child(16) {
        grid-row-start: 17;
    }

    .pollen-list > ul > li:nth-child(17) {
        grid-row-start: 18;
    }

    .pollen-list > ul > li:nth-child(18) {
        grid-row-start: 19;
    }

    .pollen-names > p,
    .pollen-names > ul > li {
        grid-column: 1;
    }

    :global(.day--0 > p),
    :global(.day--0 > ul > li) {
        grid-column: 2;
    }

    :global(.day--1 > p),
    :global(.day--1 > ul > li) {
        grid-column: 3;
    }

    :global(.day--2 > p),
    :global(.day--2 > ul > li) {
        grid-column: 4;
    }

    :global(.day--3 > p),
    :global(.day--3 > ul > li) {
        grid-column: 5;
    }

    :global(.day--4 > p),
    :global(.day--4 > ul > li) {
        grid-column: 6;
    }

    :global(.day--5 > p),
    :global(.day--5 > ul > li) {
        grid-column: 7;
    }

    :global(.day--6 > p),
    :global(.day--6 > ul > li) {
        grid-column: 8;
    }

    .pollen-list li {
        padding: .5625em .75em .625em .75em;
        font-weight: 500;
        border-radius: .33rem;
        line-height: 1.333333333;
    }

    .pollen-list > p {
        max-width: 8.0625rem;
    }

    .pollen-list__item {
        text-align: center;
        background-color: var(--color-gray);
        font-size: 1.875rem;
        line-height: 1.35 !important;
        padding: 0 !important;
        font-weight: 700 !important;
        max-width: 8.0625rem;
    }

    :global(.intensity--0) {
        color: var(--color-green);
    }

    :global(.intensity--1) {
        color: var(--color-yellow);
    }

    :global(.intensity--2) {
        color: var(--color-orange);
    }

    :global(.intensity--3) {
        color: var(--color-red);
    }

    .small {
        color: var(--color-gray-dark);
        font-size: .75rem;
        padding-left: .75rem;
    }

    dl {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 2rem;
        margin-bottom: 1rem;
        padding-left: .75rem;
    }

    dt {
        font-size: 1.875rem;
        line-height: 1.35;
        font-weight: 700;
    }

    dd {
        margin-left: .5rem;
        margin-right: 1.5rem;
        margin-top: .125em;
    }

    @media (max-width: 992px) {
        .day-list {
            font-size: .875rem;
            grid-template-columns: 3.75rem 1fr 1fr 1fr 1fr 1fr;
            padding: 0 .5rem;
            grid-gap: .5rem .3333rem;
        }

        :global(.day--5),
        :global(.day--5 ~ [class*="day-"]) {
            display: none !important;
        }

        p {
            padding: .3333rem 0;
            font-weight: 500;
        }

        .small {
            padding-left: .5rem;
        }

        .day-list > li {
            margin-left: .3125rem;
            margin-right: .3125rem;
        }

        .pollen-list li {
            padding: .3333em .5em .3333em .5em;
            font-weight: 500;
        }

        .pollen-list__item {
            font-size: 1.5rem;
        }

        dl {
            display: grid;
            grid-template-columns: 1.5rem 1fr;
            padding-left: .5rem;
            margin-top: 1.5rem;
            margin-bottom: 1rem;
        }

        dt {
            font-size: 1.5rem;
        }

        dd {
            font-size: .875rem;
        }
    }
</style>
