import App from './App.svelte';

class PollenForecast extends HTMLElement {
	constructor() {
		super();

		const shadowRoot = this.attachShadow({mode: 'closed'});
		const scriptPathSegments = document.currentScript.src.split('/');
		const scriptPath = scriptPathSegments.slice(0, scriptPathSegments.length - 1).join('/');
		const css = document.createElement('link');

		css.href = `${scriptPath}/bundle.css`;
		css.type = "text/css";
		css.rel = "stylesheet";

		shadowRoot.appendChild(css);

		new App({
			target: shadowRoot,
			props: {
				image: this.getAttribute('image') || undefined,
			},
		});
	}
}

customElements.define('pollen-forecast', PollenForecast);
