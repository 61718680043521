<script>
    import MapsApiLoader from './MapsApiLoader.svelte';
    import LocationSelector from './LocationSelector.svelte';
    import PollenForecast from './PollenForecast.svelte';

    let apiLoaded = false;
    let zip = false;

    export let image = 'map.png';
</script>

<MapsApiLoader bind:loaded={apiLoaded}/>

{#if apiLoaded}
    <LocationSelector bind:zip={zip} {image}/>
{/if}
{#if zip}
    <PollenForecast {zip}/>
{/if}

<style>
    :global(:root),
    :global(:host) {
        --color-primary: #02205b;
        --color-gray: #f0f2f2;
        --color-gray-dark: #727a82;
        --color-green: #9acd32;
        --color-yellow: #d4a914;
        --color-orange: #d25f14;
        --color-red: #bc0808;

        all: unset;
        box-sizing: border-box;
        display: block;
        width: 100%;
    }


    :global(*),
    :global(*:before),
    :global(*:after) {
        box-sizing: inherit;
    }
</style>
